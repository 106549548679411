<template>
  <div
    class="page-header-index-wide"
    style="position:relative;margin: 10px 20px;"
  >
    <a-card
      :bordered="false"
      :bodyStyle="{
        padding: '16px 16px',
        height: '100%',
        overflow: 'auto',
        'border-radius': '4px'
      }"
      :style="{ height: height, 'border-radius': '4px' }"
    >
      <a-button type="primary" @click="() => (this.show = true)"
        >添加语种</a-button
      >
      <a-button class="btns" @click="addLabelFn">添加标签项</a-button>
      <a-input-search
        placeholder="input search text"
        style="width: 200px;float: right;"
        @search="onSearch"
        :min="2"
        :max="20"
        oninput="if(value.length>20)value=value.slice(0,20)"
        onkeyup="value=value.replace(/[^\a-\z\A-\Z\_]/g,'')"
      />
      <table style="width:100%;">
        <thead>
          <th>标签</th>
          <th v-for="(item, i) in header" :key="i" v-text="item.Name"></th>
        </thead>
        <tbody>
          <tr v-for="(items, i) in info" :key="i">
            <td v-text="names[i].Name"></td>
            <td
              v-for="(item, j) in items"
              :key="j"
              @click="
                add_td_languan(
                  header[j].Label,
                  names[i].Id,
                  header[j].Name,
                  names[i].Name
                )
              "
            >
              <a-input-search
                v-if="
                  names[i].Id == defaultId && header[j].Label == defaultLabel
                "
                style="width: 220px"
                :defaultValue="item"
                @search="onLanguageEdit"
                enterButton="修改"
              />
              <span v-else>{{ item }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 分页 -->
      <a-pagination
        style="margin-top:20px;"
        :defaultCurrent="1"
        @change="onhange"
        :total="30"
      />
    </a-card>

    <!-- 点击td 弹出的输入 -->
    <a-modal
      :title="model_title"
      centered
      v-model="modal2Visible"
      @ok="subFn()"
    >
      <a-input
        type="text"
        v-model="translateVal"
        onkeyup="value=value.replace(/[^\a-\z\A-\Z\_]/g,'')"
        size="large"
        placeholder="请输入"
      />
    </a-modal>

    <transition name="fade">
      <!-- :style="{height: trans_height}" -->
      <div v-show="show" class="example">
        <div class="list" style="font-size: 16px;font-weight: 600;">
          添加语种
          <!-- <span style="font-weight: 400;">添加新语种</span> -->
        </div>
        <div class="items" style="font-weight:600;">
          <div>语言名称</div>
          <div>标识</div>
        </div>
        <div class="list_content">
          <div
            class="items"
            v-for="(list, i) in header"
            data-index="${header[i].Label_Id}"
            :key="i"
          >
            <div v-text="list.Name"></div>
            <div v-text="list.Label"></div>
            <a-popconfirm
              title="确定删除吗?"
              v-show="list.Name != '中文'"
              @confirm="confirm(list.Label_Id)"
              @cancel="cancel"
              okText="确定"
              cancelText="取消"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </div>
        </div>
        <div class="items">
          <div>
            <input
              type="text"
              maxlength="8"
              minlength="2"
              v-model="language_name"
              placeholder="输入名称"
            />
          </div>
          <div>
            <input
              type="text"
              maxlength="8"
              minlength="2"
              v-model="language_label"
              size="8"
              oninput="if(value.length>8)value=value.slice(0,8)"
              onkeyup="value=value.replace(/[^\a-\z\A-\Z]/g,'')"
              placeholder="输入标识"
            />
          </div>
          <span class="btn" @click="languageFn">添加</span>
        </div>
        <div class="items" style="margin-bottom:100px;">
          <div style="color:#222;">例如：中文</div>
          <div style="color:#222;">CN</div>
          <span></span>
        </div>
      </div>
    </transition>
    <div id="mask" v-show="show" @click="() => (this.show = false)"></div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      modal2Visible: false,
      height: '',
      // trans_height: '',
      //取出的数据
      info: '',
      names: '',
      header: '',
      //
      id: '',
      label: '',
      model_title: '', //编辑翻译与添加标签的提示title
      translateVal: '', //编辑翻译与添加标签的input
      show: false, //加语言种类
      //加语言种类的input
      language_name: '',
      language_label: '',
      pageSize: 1,
      limit: 10,
      count: '',
      defaultId: '', //要修改的参数
      defaultLabel: ''
    }
  },
  created () {
    // axios.get('http://sksaas.loc/v1/before_token',
    //   { params: { 'mobile': 17817095394 } }).then(res => {
    //   console.log(res);
    // })
    // axios.get('http://tr.sikegroup.com/V1/address/12', {
    //     header: {
    //       'Authorization': 'USERID c25haWx5X3hjeF9uZXc6M0U1TjBtR3dGSTZjZ1BoNEpLdHY4eWQyOVpqTXM3a2Y6Mzgw',
    //     },
    //     // params: { 'key': 'value' }
    //   }).then(function (response) {
    //     console.log(response)
    //   }).catch(function (error) {
    //   console.log(error);
    // });
  },
  mounted () {
    this.init()
    this.height = window.document.body.clientHeight - 182 + 'px'
    // this.trans_height = window.document.body.clientHeight - 228+'px';
  },
  methods: {
    //搜索
    onSearch (value) {
      let that = this
      console.log(value)
      // axios.post('https://smart.sikegroup.com/skim/v1/Language/get_language',
      //    {  Language: 'En', Label: value } ).then(res => {
      //   console.log(res);
      //     if(res.data.code==1){
      //       console.log(res.data.message)

      //       }else{
      //         if(res.data.code==0){that.$message.error(res.data.message);}else{
      //           that.$message.warning(res.data.message);
      //         }
      //       }
      // })
    },
    init () {
      let that = this
      axios
        .get('https://smart.sikegroup.com/skim/v1/Language/language_list', {
          params: { Page: that.pageSize, Limit: 10 }
        })
        .then(res => {
          console.log(res)
          if (res.data.code == 1) {
            that.info = res.data.message.Langue
            that.names = res.data.message.Name
            that.header = res.data.message.Header
            that.count = res.data.message.Count
          } else {
            if (res.data.code == 0) {
              that.$message.error(res.data.message)
            } else {
              that.$message.warning(res.data.message)
            }
          }
        })
    },
    //delete语言（种类）
    //确定删除
    confirm (e) {
      console.log(e)
      let that = this
      axios
        .post('https://smart.sikegroup.com/skim/v1/Language/del_language', {
          Id: e
        })
        .then(res => {
          //  console.log(res);
          if (res.data.code == 1) {
            that.$message.success('删除成功!')
            that.show = false
            that.init()
          } else {
            if (res.data.code == 0) {
              that.$message.error(res.data.message)
            } else {
              that.$message.warning(res.data.message)
            }
          }
        })
    },
    //分页 点击页码回掉事件
    onhange (page, size) {
      console.log(132, page, size)
      this.pageSize = page
      this.limit = size
      this.init()
    },

    //取消删除
    cancel (e) {
      console.log(e)
      // this.$message.error('Click on No')
    },

    //添加语言（种类）
    languageFn () {
      let that = this
      that.language_label = that.language_label.replace(/[^\a-\z\A-\Z]/g, '')
      if (!that.language_label || !that.language_name) {
        that.$message.error('请填写完整！')
        return false
      }
      if (that.language_label.length < 2 || that.language_name.length < 2) {
        that.$message.error('字数不能少于2个,标识只能输入字母')
        return false
      }
      axios
        .post('https://smart.sikegroup.com/skim/v1/Language/language_add', {
          Lable: that.language_label,
          Name: that.language_name
        })
        .then(res => {
          console.log(res)
          if (res.data.code == 1) {
            that.show = false
            that.language_name = ''
            that.language_label = ''
            that.$message.success('添加成功!')
            that.init()
          } else {
            if (res.data.code == 0) {
              that.$message.error(res.data.message)
            } else {
              that.$message.warning(res.data.message)
            }
          }
        })
    },
    //添加标签项
    addLabelFn () {
      let that = this
      that.modal2Visible = true
      that.model_title = '添加新标签项'
    },
    //添加语言翻译
    add_td_languan (label, id, language, name) {
      console.log(id, label, name, language)
      if (!id || !label || !name || !language) {
        return false
      }
      this.defaultId = id
      this.defaultLabel = label
      let that = this
      // that.model_title=`“${name}”翻译成${language}为:`;
      that.label = label
      that.id = id
      // that.modal2Visible = true;
    },
    //语言翻译修改
    onLanguageEdit (value) {
      let that = this
      if (!value || value.length < 2) {
        that.$message.error('输入字数不少于2个')
        return false
      }
      axios
        .post(
          'https://smart.sikegroup.com/skim/v1/Language/language_translate',
          { Id: that.id, Label: that.label, Content: value }
        )
        .then(res => {
          // console.log(res)
          if (res.data.code == 1) {
            // that.translateVal = '';
            // that.modal2Visible = false;
            that.defaultLabel = ''
            that.defaultId = ''
            that.$message.success('提交成功!')
            that.init()
          } else if (res.data.code == 0) {
            that.$message.error(res.data.message)
          } else {
            that.$message.warning(res.data.message)
          }
        })
    },
    //添加标签项 (sub共用 model的确定按钮)
    subFn () {
      let that = this
      console.log(that.translateVal)
      if (that.model_title == '添加新标签项') {
        if (!that.translateVal) {
          that.$message.error('不能为空!')
          return false
        }
        if (that.translateVal.length < 3) {
          console.log(123)
          that.$message.error('不能少于3!')
          return false
        }
        let reg = /^[a-zA-Z_]+$/

        if (reg.test(that.translateVal) === false) {
          that.$message.error('只能输入字母，请重新输入')
          return false
        }

        axios
          .post('https://smart.sikegroup.com/skim/v1/Language/language_label', {
            Name: that.translateVal
          })
          .then(res => {
            console.log(res.data.code)
            if (res.data.code == 1) {
              that.translateVal = ''
              that.modal2Visible = false
              that.$message.success('提交成功!')
              that.init()
            } else if (res.data.code == 0) {
              that.$message.error(res.data.message)
            } else {
              that.$message.warning(res.data.message)
            }
          })
      } else {
        if (!that.translateVal || that.translateVal.length < 2) {
          that.$message.error('输入字数不少于2个')
          return false
        }
        axios
          .post(
            'https://smart.sikegroup.com/skim/v1/Language/language_translate',
            { Id: that.id, Label: that.label, Content: that.translateVal }
          )
          .then(res => {
            // console.log(res)
            if (res.data.code == 1) {
              that.translateVal = ''
              that.modal2Visible = false
              that.$message.success('提交成功!')
              that.init()
            } else if (res.data.code == 0) {
              that.$message.error(res.data.message)
            } else {
              that.$message.warning(res.data.message)
            }
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.btns {
  margin-bottom: 20px;
  margin-left: 30px;
}
.editable-row-operations a {
  margin-right: 8px;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.ant-card::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
.ant-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: lightgray;
}

/*定义滑块 内阴影+圆角*/
.ant-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: blue;
}
/*定义最上方和最下方的按钮*/
.ant-card::-webkit-scrollbar-button {
  background-color: #000;
  border: 1px solid yellow;
}

table {
  border: 1px solid #ddd;
}
table tr:hover {
  background: #cecece;
  color: #fff;
}
th,
td {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  min-width: 100px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
}
.example {
  width: 360px;
  height: 100%;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 1px 14px #cccccc;
  border-radius: 2px;
  right: 0px;
  top: 0px;
  z-index: 100;
  padding: 16px;
  // top: -24px;
  // span{
  //     color: #3780FF;
  //     float: right;
  // }
  a {
    float: right;
  }
  .list {
    color: #333333;
    font-size: 14px;
    padding: 16px 0px;
    border-bottom: 1px solid #dfdfdf;
  }
  .items {
    color: #333333;
    font-size: 14px;
    padding: 16px 0px;
    border-bottom: 1px solid #dfdfdf;
  }
  .items > div {
    width: 33%;
    display: inline-block;
  }
  .items .btn {
    padding: 4px 10px;
    color: #fff;
    background: #3780ff;
    border-radius: 2px;
    cursor: pointer;
    float: right;
  }
  .items input {
    border: 0px solid;
    outline: none;
  }
  /* .list_content .list{
        cursor: move;
    } */
  .list_content .items span {
    cursor: pointer;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.7s;
}
.fade-enter,
.fade-leave-to {
  right: -100%;
}
.fade-enter-to,
.fade-leave {
  right: 0%;
}
#mask {
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}
</style>
